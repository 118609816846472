import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import './NightForm.css';
import axios from "axios"; 

function NightForm() {

const [email, setEmail ] = useState(null);
const [name, setName] = useState(null);
const [wallet, setWallet] = useState(null);
const [message, setMessage] = useState(null);
const [newsletter, setNewsletter] = useState(null);


const [subConfirm, setSubConfirm] = useState(null);


const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
    console.log(email);
};

const handleChangeWallet = (event) => {
    const wallet = event.target.value;
    setWallet(wallet);
};

const handleChangeName = (event) => {
    const name = event.target.value;
    setName(name);
};

const handleChangeMessage = (event) => {
    const message = event.target.value;
    setMessage(message);
};

const handleChangeNewsletter = (event) => {
    const newsletter = event.target.checked;
    setNewsletter(newsletter);
};

const handleSubmit = (event) => {
    event.preventDefault();
    if(newsletter === true){
        axios.get('https://www.degendistillery.com/rest/api/postSubscription.php?email='+email+"&wallet="+wallet)
        .then(res=> {
            console.log(res);
            setSubConfirm("Success!")
        })
    }
    axios.get('https://www.degendistillery.com/rest/api/postEmail.php?email='+email+"&wallet="+wallet+"&name="+name+"&message="+message)
    .then(res=> {
        console.log(res);
        setSubConfirm("Success!")
    })
}

  return (
        <Row className='nightForm__formContainer'>
            <Col xs={12} md={5} className="mb-3">
                <p className='text-md-start h3'>Contact Us</p>
                <p className='text-md-start h6' style={{color: "#B0B0B0"}}><span style={{color:"#ff346e"}}>Join the Party!</span><br></br><br></br>Contact us for any queries about our projects, or if you are part of our community and need any kind of support. Also register for news and announcements on our Company.</p>
            </Col>
            <Col xs={12} md={7}>
                <Form className='text-center' onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control className='textInput' type="email" name='email' placeholder="email (required)" onChange={handleChangeEmail} required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="wallet">
                        <Form.Control className='textInput' type="text" name='wallet' placeholder="wallet address (optional)" onChange={handleChangeWallet} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Control className='textInput' type="text" name='name' placeholder="name (optional)" onChange={handleChangeName} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="message">
                        <Form.Control className='textInput' as="textarea" rows={5} name='message' placeholder="your message" onChange={handleChangeMessage} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="newsletter">
                        <Form.Check type='checkbox' name='newsletter' style={{display:'inline'}} onChange={handleChangeNewsletter} />
                        <p style={{display:'inline'}} className='ms-2'>Join our newsletter</p>
                    </Form.Group>
                    <Button className='degenButton' type="submit">
                        Send
                    </Button>
                    <p className='mt-3' style={{color:"#D6FF01"}}>{subConfirm}</p>
                </Form>
            </Col>
        </Row>     
  );
}

export default NightForm;