import React from 'react';

import './NightMenu.css';

function NightMenu() {
  return (
    <div>
      <ul className="nightMenu__Navbar" >
        <li className='nightMenu__Link' onClick={() => window.location.replace("/#project")}>PROJECT</li>
        <li className='nightMenu__Link' onClick={() => window.location.replace("/#team")}>TEAM</li>
        <li className='nightMenu__Link' onClick={() => window.location.replace("/#community")}>COMMUNITY</li>
      </ul>
    </div>
  );
}

export default NightMenu;