import React from 'react';

import logoDegenLabs from '../../img/logoDegenLabs.svg';
import cube from '../../img/cube.gif';

import sammy from '../../img/sammy.jpg';
import jacopo from '../../img/jacopo.jpg';
import thomas from '../../img/thomas.jpg';
import anthony from '../../img/anthony.jpg';
import gabriele from '../../img/gabriele.jpg';
import giuliano from '../../img/giuliano.jpg';
import joey from '../../img/joey.jpg';
import iamfr4nklyn from '../../img/iamfr4nklyn.jpg';

import twitter from '../../img/twitter.png';
import twitterW from '../../img/twitterW.png';

import linkedin from '../../img/linkedin.png';
import degenerationLogo from '../../img/degenerationLogo.svg';

import NightMenu from './menu/NightMenu';
import NightForm from './form/NightForm';
import NightFooter from './footer/NightFooter';

import { Container, Row, Col } from 'react-bootstrap';

const NightHome = () => {
    
    return (
        <>
        <div className='nightHome__App'>
            <Container className='nightHome__Container'>
                <Row>
                    <Col xs={12} className="mt-5">
                        <div className='nightHome__logoContainer text-center'>
                            <img src={logoDegenLabs} alt="logo Degen"  className='nightHome__logoVoltText img-fluid' />
                        </div>
                    </Col> 
                    <Col lg={12} className="mt-5 d-none d-md-block">
                        <NightMenu />
                    </Col>
                    <Col lg={12}>
                        <div className='nightHome__videoContainer'>
                            <img src={cube} alt="cube"  className='nightHome__video' />
                        </div>
                    </Col>                                 
                </Row>
                <div id="project">
                    <Row>
                        <Col lg={12}>
                            <div className='nightHome__heroContainer'>
                                <h1 className='nightHome__heroText'>Beyond disruptive.<br></br>This is decentralisation.</h1>
                            </div>
                        </Col>                  
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className='nightHome_valuesContainer mt-5'>
                                <h2 className='nightHome_valuesTitle'>Innovation, not iteration</h2>
                                <p className='nightHome_valuesText'>We are a Community of innovators, building products that help people succeed, and duty bound to share value with those who help us grow.</p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className='nightHome_valuesContainer mt-5'>
                                <h2 className='nightHome_valuesTitle'>Disruptors to the core</h2>
                                <p className='nightHome_valuesText'>We are here to disrupt the status-quo, ensuring those who embrace change can thrive through the power of web3 communities.</p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className='nightHome_valuesContainer mt-5'>
                                <h2 className='nightHome_valuesTitle'>Revolutionising Technology</h2>
                                <p className='nightHome_valuesText'>We are using technology to change the world, co-creating value ownership and creation tools that will fundamentally shift how people are rewarded for their time.</p>
                            </div>
                        </Col>    
                        <Col lg={12}>
                            <div className='text-center px-5 mt-5'>
                                <h2 className='nightHome_valuesTitle' style={{color:"#ff346e", fontSize:"55px"}}>This is happening</h2>
                                <h2 className='nightHome_valuesTitle'>Join Us</h2>
                            </div>
                        </Col>                       
                    </Row>
                </div>
                <div id='contact'>
                    <Row>
                        <Col lg={12} className="mt-5">
                            <NightForm />
                        </Col>                       
                    </Row>
                </div>  
                <div id="team">
                    <Row>
                        <Col lg={12}>
                            <div className='nightHome_teamContainer'>
                                <h3>Meet the Team</h3>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={sammy} alt="Sammy"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Sammy</p>
                                <span className='nightHome__memberRole'>CEO</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://twitter.com/sammy_verghese'><img src={twitter} alt="twitter"  className='nightHome__memberSocialIcon' /></a>
                                    <a href='https://uk.linkedin.com/in/sammy-verghese'><img src={linkedin} alt="linkedin"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={thomas} alt="Thomas"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Thomas</p>
                                <span className='nightHome__memberRole'>CCO</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://twitter.com/dogestonks_'><img src={twitter} alt="twitter"  className='nightHome__memberSocialIcon' /></a>
                                    <a href='https://www.linkedin.com/in/webstertp/'><img src={linkedin} alt="linkedin"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>                
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={giuliano} alt="Giuliano"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Giuliano</p>
                                <span className='nightHome__memberRole'>CTO</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://twitter.com/Giulian34029212'><img src={twitter} alt="twitter"  className='nightHome__memberSocialIcon' /></a>
                                    <a href='https://www.linkedin.com/in/giuliano-neroni-901217151/'><img src={linkedin} alt="linkedin"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>                    
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={jacopo} alt="Jacopo"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Jacopo</p>
                                <span className='nightHome__memberRole'>CPO</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://mobile.twitter.com/jacopo_neroni'><img src={twitter} alt="twitter"  className='nightHome__memberSocialIcon' /></a>
                                    <a href='https://www.linkedin.com/in/jacopo-neroni-29061a61/'><img src={linkedin} alt="linkedin"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={anthony} alt="Anthony"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Anthony</p>
                                <span className='nightHome__memberRole'>CFO</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://www.linkedin.com/in/anthony-lilleyman/'><img src={linkedin} alt="linkedin"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={gabriele} alt="Gabriele"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Gabriele</p>
                                <span className='nightHome__memberRole'>Technical Director</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://www.linkedin.com/in/gabriele-de-propris-b5b06434/'><img src={linkedin} alt="linkedin"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>     
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={joey} alt="Joey"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Joey</p>
                                <span className='nightHome__memberRole'>Creative Director</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://twitter.com/joeytadiar'><img src={twitter} alt="twitter"  className='nightHome__memberSocialIcon' /></a>
                                    <a href='https://uk.linkedin.com/in/joeytadiar'><img src={linkedin} alt="linkedin"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>                         
                        <Col lg={3} xs={6}>
                            <div className='nightHome_memberContainer'>
                                <img src={iamfr4nklyn} alt="iamfr4nklyn"  className='nightHome__memberPhoto' />
                                <p className='nightHome__memberName'>Iamfranklyn</p>
                                <span className='nightHome__memberRole'>Just tagging along...</span>
                                <div className='nightHome__memberSocialContainer'>
                                    <a href='https://twitter.com/iamfr4nklyn'><img src={twitter} alt="twitter"  className='nightHome__memberSocialIcon' /></a>
                                </div>
                            </div>
                        </Col>               
                    </Row>
                </div>
                <div id="community">
                    <Row>
                        <Col lg={12} className="nightHome_avatarBg mt-5">
                            <div className='nightHome_degenerationContainer'>
                                <img src={degenerationLogo} alt="linkedin"  className='nightHome__degenerationLogo mb-5' />
                                <h4>Your next-gen digital identity awaits.<br></br>Degeneration are the future-thinkers powering Degen Distillery.</h4>
                                <h4 className='mt-5'>Arriving this summer.</h4>
                                <a href='https://twitter.com/degendistillery'><img src={twitterW} alt="twitter"  className='nightHome__degenerationTwitter' /></a>
                            </div>
                        </Col>
                    </Row> 
                </div>  
            </Container>            
        </div>
        <NightFooter />
        </>
    );
};
  
export default NightHome;