import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './NightFooter.css';

import logoDegenLabsIcon from '../../../img/logoDegenLabsIcon.svg';

function NightFooter() {
    return (
    <footer className="nightFooter__footerContainer">
        <Container className='pt-4'>
            <Row>
                <Col lg={6} md={12}>
                    <h4 className='nightFooter__mainTitle'>CONNECT<br></br>WITH US</h4>
                    <div className='nightFooter__socialLinks'>
                        <a className='nightFooter__whiteLink' href='https://twitter.com/degendistillery'>Twitter</a>
                        <a className='nightFooter__whiteLink' style={{color:'#7B7B7B'}} href='#about'>Discord - Opening soon</a>
                    </div>

                </Col>
                <Col lg={6} md={12}>
                    <div className='nightFooter__logoContainer'>
                        <img src={logoDegenLabsIcon} alt="degen labs logo" className="nightFooter__logoVolt" />
                    </div>
                    
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={12}>
                    <div className='nightFooter__copyContainer'>                        
                        <p className='nightFooter__copyText'>Degen Labs © 2023</p>
                    </div>
                </Col>
                <Col lg={6} md={12}>
                    <div className='nightFooter__legalContainer'>
                        <a className='nightFooter__legalLink' href='#about'>Terms & Conditions</a>
                        <a className='nightFooter__legalLink  ms-3' href='#about'>Privacy Policy</a>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
    );
  }
  
  export default NightFooter;