import './App.css';

import "./components/fonts/RedHatDisplay-Black.ttf";
import "./components/fonts/RedHatDisplay-Bold.ttf";
import "./components/fonts/RedHatDisplay-BoldItalic.ttf";
import "./components/fonts/RedHatDisplay-ExtraBold.ttf";
import "./components/fonts/RedHatDisplay-Medium.ttf";
import "./components/fonts/RedHatDisplay-SemiBold.ttf";

//import NightLanding from './components/landing/night/NightLanding';
import NightHome from './components/home/night/NightHome';

function App() {
  return (
    <div className="App">
      <NightHome /> 
    </div>
  );
}

export default App;
